<template>
  <div class="prod-extra is-loading" v-if="isLoading">
    <BrandCard :isLoading="true" />
    <div
      class="flex items-center p-4 bg-white justify-center rounded-lg shadow-box overflow-hidden"
    >
      <div class="loader h-5 w-full"></div>
    </div>
    <div
      class="flex flex-col items-stretch p-4 gap-3 bg-white rounded-lg shadow-box overflow-hidden"
    >
      <div class="flex items-center justify-between" v-for="x in 5" :key="x">
        <div class="flex items-center space-x-3">
          <div class="loader w-10 h-10"></div>
          <p class="loader">XXXX XXX</p>
        </div>
        <div class="w-16 h-4 loader"></div>
      </div>
    </div>
  </div>
  <div v-else class="prod-extra">
    <slot />
  </div>
</template>

<script>
import BrandCard from "./BrandCard.vue";

export default {
  name: "ProductInfo",
  props: {
    isLoading: { type: Boolean, default: false },
  },
  components: { BrandCard },
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.prod-extra {
  @apply w-full flex-shrink-0 space-y-8;

  @media (min-width: 1537px) {
    @apply w-96 pr-6;
  }
  &.is-loading {
    .loader {
      @apply inline-block text-transparent bg-gray-100 animate-pulse rounded-md;
    }
  }
}
</style>
