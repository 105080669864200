<template>
  <Card class="category-card">
    <router-link :to="route ?? '/categories'" @click="goto" class="inner">
      <div
        class="trend-image"
        v-if="$slots.default && imagePosition !== 'right'"
      >
        <slot />
      </div>
      <div class="flex flex-col flex-grow justify-center h-auto md:h-full">
        <div>
          <h3 v-if="title">{{ title }}</h3>
          <h3 v-else>{{ firstline }}<br />{{ secondline }}</h3>
          <button class="text-theme-dark uppercase">
            {{ cta || "Browse"
            }}<Icon name="right-arrow" class="w-5 h-5 -my-2 ml-1" />
          </button>
        </div>
      </div>
      <div
        class="trend-image"
        v-if="$slots.default && imagePosition === 'right'"
      >
        <slot />
      </div>
    </router-link>
  </Card>
</template>

<script>
export default {
  name: "CategoryCard",
  props: {
    firstline: String,
    secondline: String,
    title: String,
    product: String,
    route: String,
    linkFilter: Object,
    imagePosition: {
      type: String,
      default: "right",
    },
    cta: String,
  },
  methods: {
    goto() {
      if (this.linkFilter) {
        let opt = {
          id: this.linkFilter.brand_id,
          name: this.linkFilter.brand,
          slug: this.linkFilter.brand.toLowerCase().replace(" ", "-"),
        };
        this.$store.dispatch("selectedOption", opt);
        // setTimeout(() => {
        //   console.log(`${opt.slug}-${opt.id}`)
        //   console.log(document.getElementById(`${opt.slug}-${opt.id}`))
        //   // document.getElementById(`${opt.slug}-${opt.id}`).checked = true
        // }, 10000);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.category-card {
  @apply flex items-stretch h-36 p-0 bg-white;

  &.shaded {
    background: #e2dff2;
  }

  .inner {
    @apply flex items-center justify-center text-white px-5 md:px-8 py-4 md:py-6 h-full relative space-x-4;
  }

  h3 {
    @apply text-lg md:text-xl font-semibold mb-2 text-black;
  }

  button {
    @apply flex items-center text-sm font-medium;
  }
}
</style>
