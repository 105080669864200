<template>
  <div class="bundle-table">
    <slot name="header" />
    <table>
      <!-- <thead class="bg-white">
        <tr>
          <th scope="col" class="text-left">PRODUCT</th>
          <th scope="col" width="100" class="text-center">Qty</th>
        </tr>
      </thead> -->
      <tbody>
        <tr v-for="(item, i) in bundleItems" :key="i">
          <td>
            <div class="bundle-item">
              <img
                :src="
                  item.base_path + '/' + item.file_name || '/images/medusa.png'
                "
                alt="Image"
              />
              <a
                :href="'/products/' + item.slug || '#'"
                target="_blank"
                class="bundle-item-info"
              >
                <p>{{ item.product_name }}</p>
                <span>{{ item.name }}</span>
              </a>
            </div>
          </td>
          <td class="text-center" width="100">
            <div class="flex items-center justify-center h-full pt-1">
              Qty. {{ item.quantity }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <button
      v-if="items && items.length > 5"
      class="block text-xs text-theme-primary px-5 py-2 focus:outline-none mb-2"
      @click="showAll = !showAll"
    >
      View {{ showAll ? "Less" : "All" }}
    </button>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import clone from "lodash/clone";

export default {
  name: "BundleItems",
  props: {
    items: Object,
  },
  setup(props) {
    const showAll = ref(false);

    const bundleItems = computed(() => {
      if (showAll.value || props.items.length < 6) {
        return props.items;
      }
      return clone(props.items).splice(0, 5);
    });

    return {
      showAll,
      bundleItems,
    };
  },
};
</script>

<style lang="scss" scoped>
.bundle-table {
  @apply w-full;

  table {
    @apply min-w-full divide-y divide-gray-100 py-0 my-0;
    width: 100%;
    word-break: break-word;

    th {
      @apply px-6 py-1.5 text-xxs font-medium text-brand-body uppercase tracking-wider;

      &:not(:first-child) {
        @apply px-2 md:px-4;
      }
    }

    tbody {
      @apply bg-white text-xs text-brand-secondary font-medium;

      td {
        @apply px-5 py-4 font-normal;

        &.hightlight {
          @apply font-semibold;
        }

        .bundle-item {
          @apply flex items-center space-x-3 flex-shrink-0;
          img {
            @apply w-8 h-8 m-0 mr-1 object-contain object-center;
          }
          .bundle-item-info {
            @apply flex-grow no-underline;
            p {
              @apply m-0 text-xs leading-none text-brand-secondary font-semibold mb-0.5;
            }
            span {
              @apply text-xxs leading-none text-brand-gray font-normal;
            }

            &:hover {
              p {
                @apply text-black;
              }
            }
          }
        }
      }
    }
  }
}
.info-title {
  @apply flex items-center justify-between px-4 py-3;
  .title {
    @apply flex items-center text-md text-theme-secondary font-light space-x-2;
    svg {
      @apply w-5 h-5;
    }
  }
  .price {
    @apply text-sm text-black font-semibold;
  }
}
</style>
