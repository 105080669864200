<template>
  <div class="prod-header is-loading" v-if="isLoading">
    <div>
      <h3>
        Product Title Here
      </h3>
      <ol role="list" class="breadcrumbs mt-2">
        <li>
          <a href="#"
            ><span>
              Home
            </span></a
          >
          <em>/</em>
        </li>
        <li>
          <a href="#">
            <span>Brand</span>
          </a>
          <em>/</em>
        </li>
        <li>
          <a href="#">
            <span>Product Url</span>
          </a>
        </li>
      </ol>
    </div>
    <button>X</button>
  </div>
  <div class="prod-header" v-else>
    <div>
      <h3>
        {{ productInfo.name }}
      </h3>
      <ol role="list" class="breadcrumbs">
        <li>
          <router-link :to="'/home'"
            ><span>
              Home
            </span></router-link
          >
          <em>/</em>
        </li>
        <li class="flex items-center flex-shrink-0 gap-1">
          <router-link :to="'/brand' +productInfo.brandUrl">
            <span>
              {{ productInfo.brandName }}
            </span>
          </router-link>
          <em>/</em>
        </li>
        <li>
          <span>
            {{ productInfo.name }}
          </span>
        </li>
      </ol>
    </div>
    <div class="fav-button" v-if="isLoggedIn" @click="$emit('onFavClick')">
      <div class="lottie-heart" v-if="isFavorite">
        <Vue3Lottie
          :animationData="heartJson"
          :height="55"
          :width="55"
          :loop="false"
        />
      </div>
      <button class="favourite" v-else>
        <svg
          width="20"
          height="20"
          class="w-6 h-6 d-none"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10 2.91501C11.09 1.63501 12.76 0.825012 14.5 0.825012C17.58 0.825012 20 3.24501 20 6.32501C20 10.1019 16.6056 13.18 11.4627 17.8435L11.45 17.855L10 19.175L8.55 17.865L8.51052 17.8291C3.38263 13.1693 0 10.0953 0 6.32501C0 3.24501 2.42 0.825012 5.5 0.825012C7.24 0.825012 8.91 1.63501 10 2.91501ZM10 16.475L10.1 16.375C14.86 12.065 18 9.21501 18 6.32501C18 4.32501 16.5 2.82501 14.5 2.82501C12.96 2.82501 11.46 3.81501 10.94 5.18501H9.07C8.54 3.81501 7.04 2.82501 5.5 2.82501C3.5 2.82501 2 4.32501 2 6.32501C2 9.21501 5.14 12.065 9.9 16.375L10 16.475Z"
            fill="#A4A4A4"
            fill-opacity="0.54"
          />
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import "vue3-lottie/dist/style.css";
import { Vue3Lottie } from "vue3-lottie";
const heartJson = require("@/assets/lottie/heart.json");

export default {
  name: "ProductTitle",
  props: {
    info: {
      type: Object,
      required: true,
    },
    isLoggedIn: {
      type: Boolean,
      default: false,
    },
    isLoading: { type: Boolean, default: false },
    isFavorite: { type: Boolean, default: false },
  },
  emits: ["onFavClick"],
  components: {
    Vue3Lottie,
  },
  setup(props) {
    const productInfo = computed(() => {
      const { product, brand_slug } = props.info;

      return {
        name: product?.name || "",
        brandUrl: `${process.env.BASE_URL.replace(/\/$|$/, "/")}${brand_slug ||
          ""}`,
        brandName: product?.brand || "Brand",
      };
    });

    return {
      productInfo,
      heartJson,
    };
  },
};
</script>

<style lang="scss" scoped>
.prod-header {
  @apply flex items-start justify-between;

  h3 {
    @apply text-xl lg:text-3xl font-semibold text-brand-primary mb-1 leading-snug;
  }

  .breadcrumbs {
    @apply flex items-center flex-wrap gap-1;

    li {
      @apply flex items-center flex-shrink-0 gap-1;

      span {
        @apply text-sm text-brand-secondary text-opacity-60 font-light tracking-wide;
      }
      em {
        @apply text-sm text-brand-secondary text-opacity-60 font-normal;
      }
    }
  }

  .fav-button {
    @apply relative cursor-pointer;
  }

  .lottie-heart {
    @apply -mr-5 -mt-3.5;
  }

  .favourite {
    @apply -mr-1;
    margin-top: 3px;
  }

  &.is-loading {
    h3,
    .breadcrumbs li {
      @apply inline-block text-transparent bg-gray-100 animate-pulse rounded-md mr-2;

      span,
      em {
        @apply text-transparent;
      }
    }
    button {
      @apply bg-gray-100 animate-pulse rounded-md w-8 h-8 text-transparent;
    }
  }
}
</style>
