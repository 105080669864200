<template>
  <div class="product-images is-loading" v-if="isLoading">
    <div class="large-preview">
      <div class="img"></div>
    </div>
    <div class="preview-grid non-slider">
      <div v-for="x in 8" :key="x" class="preview-img" />
    </div>
  </div>
  <template v-else>
    <VueEasyLightbox
      :visible="showLightbox"
      :imgs="getImages"
      :index="galleryCurrentIndex"
      @hide="closeLightbox"
    />
    <div class="product-images" v-if="isGalleryEnabled">
      <div
        class="flex items-center justify-center -mx-5"
        @click.once="reloadSlider()"
      >
        <button
          class="agile-arrow"
          :class="{ inactive: !isNextTriggered }"
          @click="$refs.largePreview.goToPrev()"
        >
          <ChevronLeftIcon />
        </button>
        <VueAgile
          ref="largePreview"
          :throttle-delay="0"
          :fade="true"
          @after-change="onSlideChange($event)"
          class="large-preview"
        >
          <img
            v-for="(img, idx) in getImages"
            :key="img.id"
            :src="img.src"
            @click="() => openLightbox(idx)"
          />
        </VueAgile>
        <button
          class="agile-arrow"
          @click="$refs.largePreview.goToNext(), (isNextTriggered = true)"
        >
          <ChevronRightIcon />
        </button>
      </div>
      <ImagesGrid
        :images="getImages"
        :current="activeImage"
        @change="updateCurrentSlide"
      />
    </div>
    <div class="product-images" v-else>
      <div class="large-preview">
        <img
          :src="
            getImages[activeImage || 0]
              ? getImages[activeImage || 0].src
              : defaultImg
          "
          ref="nonGalleryPreview"
          alt="Image description"
          @click="() => openLightbox(activeImage || 0)"
        />
      </div>
      <div class="preview-grid non-slider">
        <div
          v-for="(img, idx) in getImages"
          :key="img.id"
          class="preview-img cursor-pointer"
          @click="onImgClick(img.src, idx)"
        >
          <img :src="img.src" :class="{ active: idx === activeImage }" />
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import { ref, computed, watch } from "vue";
import { VueAgile } from "vue-agile";
import ImagesGrid from "./ImagesGrid.vue";
import VueEasyLightbox from "vue-easy-lightbox";
import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/vue/solid";
export default {
  name: "ProductImages",
  props: {
    isLoggedIn: {
      type: Boolean,
      default: false,
    },
    images: {
      type: Array,
      default: () => [],
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    defaultImg: {
      type: String,
      default: "",
    },
    changedImg: {
      type: String,
      default: "",
    },
  },
  components: {
    VueAgile,
    ImagesGrid,
    VueEasyLightbox,
    ChevronRightIcon,
    ChevronLeftIcon,
  },
  setup(props) {
    const galleryCurrentIndex = ref(null);
    const showLightbox = ref(false);
    const largePreview = ref(null);
    const isLoaded = computed(() => !props.isLoading);

    const isNextTriggered = ref(false);
    const activeImage = ref(0);
    const nonGalleryPreview = ref(null);

    const getUpdatedImg = computed(() => props.changedImg);

    const getImages = computed(() =>
      props.images.map((img) => ({
        src: `${img.base_path}/${img.file_name}`,
        file_name: img.file_name,
        title: img.name,
        alt: img.name,
      }))
    );

    const isGalleryEnabled = computed(() => getImages.value.length > 3);

    const openLightbox = (idx) => {
      showLightbox.value = true;
      galleryCurrentIndex.value = idx;
    };

    const closeLightbox = () => {
      showLightbox.value = false;
    };

    const reloadSlider = () => {
      largePreview?.value?.reload();
    };

    const onSlideChange = (e) => {
      activeImage.value = e.currentSlide;
    };

    const updateCurrentSlide = (index) => {
      activeImage.value = index;
      isNextTriggered.value = true;
      largePreview.value.goTo(index);
      reloadSlider();
    };

    const onImgClick = (url, idx) => {
      activeImage.value = idx;
      if (nonGalleryPreview.value) {
        nonGalleryPreview.value.src = url;
      }
    };

    watch(isLoaded, () => {
      setTimeout(() => {
        reloadSlider();
      }, 350);
    });

    watch(getUpdatedImg, (imgName) => {
      const clickIndex = getImages.value.findIndex(
        (img) => img.file_name === imgName
      );
      if (clickIndex == -1) {
        return;
      }
      if (!isGalleryEnabled.value) {
        onImgClick(getImages.value[clickIndex].src, clickIndex);
        return;
      }
      updateCurrentSlide(clickIndex);
    });

    return {
      getImages,
      activeImage,
      showLightbox,
      largePreview,
      isNextTriggered,
      isGalleryEnabled,
      nonGalleryPreview,
      galleryCurrentIndex,
      onImgClick,
      openLightbox,
      reloadSlider,
      onSlideChange,
      closeLightbox,
      updateCurrentSlide,
    };
  },
};
</script>

<style lang="scss" scoped>
.product-images {
  .large-preview {
    @apply flex items-center justify-center pb-5;

    img {
      cursor: zoom-in;
    }
  }
  .preview-grid {
    &.non-slider {
      @apply w-full grid grid-cols-6 2xl:grid-cols-4 gap-3;
    }
    @apply w-full;
    .preview-img {
      @apply flex items-center justify-center;
      img {
        @apply rounded border border-gray-200 max-w-full cursor-pointer;

        &:hover,
        &.active {
          border-color: #7ab1e4;
        }
      }
    }
  }

  &.is-loading {
    .large-preview,
    .preview-grid {
      .img,
      .preview-img {
        @apply w-full bg-gray-100 animate-pulse rounded-md;
        aspect-ratio: 1/1;
      }
    }
  }
}
</style>
