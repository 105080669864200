<template>
  <div class="product-analytics" v-if="data">
    <div class="flex items-center justify-between px-4 py-2">
      <div class="flex items-center space-x-2">
        <Icon name="ranking" class="w-5 h-5" />
        <span class="text-sm uppercase text-brand-body font-normal"
          >Medusa Rank</span
        >
        <a href="#"
          ><img src="/images/pro.svg" class="h-4" alt="pro" v-if="!isLoggedIn"
        /></a>
      </div>
      <span
        class="text-brand-ash cursor-pointer hover:text-brand-body"
        v-tippy="{
          content: `This shows how popular ${
            data.product ? data.product.name : 'product'
          } is in the ${
            data.product ? data.product.product_type : ''
          } category and the ${data.product ? data.product.brand : ''} brand.`,
          placement: 'top',
        }"
      >
        <Icon name="info" class="w-5 h-5" />
      </span>
    </div>
    <div class="pa-stats">
      <BlurryWrap class="blurry-banner" v-if="!isLoggedIn">
        <Icon name="lock-filled" />
        <p>Members can view intelligence data</p>
      </BlurryWrap>
      <div :class="!isLoggedIn ? 'blurry-content' : ''">
        <p><strong>#</strong>{{ data && isLoggedIn ? data.rank : "" }}</p>
        <router-link
          :to="slugUrl('category/' + data.product.category.slug)"
          @click="setRank"
          class="pa-link"
          >in {{ data.product ? data.product.product_type : null }}</router-link
        >
      </div>
      <div :class="!isLoggedIn ? 'blurry-content' : ''">
        <p><strong>#</strong>{{ data && isLoggedIn ? data.rank_brand : "" }}</p>
        <router-link
          :to="slugUrl('brand/' + data.brand_slug)"
          @click="setRank"
          class="pa-link"
          >in {{ data.product ? data.product.brand : null }}</router-link
        >
      </div>
    </div>
    <template
      v-if="
        !data.rank ||
          data.rank.toString() === '00' ||
          data.rank.toString() === '0'
      "
    >
      <template
        v-if="
          isDateCheck(data.product.variants.map((r) => r.first_landed_date))
        "
      >
        <div
          v-if="
            checkFirstLanded(
              data.product.variants.map((r) => r.first_landed_date)
            )
          "
          class="pa-banner"
        >
          <div class="contents">
            <div class="flex items-center space-x-1">
              <Icon name="triangle-warn" class="w-3 h-3" />
              <span>Too New To Rank</span>
            </div>
            <span>JUST LAUNCHED</span>
          </div>
        </div>
      </template>
      <div v-else class="pa-banner">
        <div class="contents">
          <div class="flex items-center space-x-1">
            <Icon name="triangle-warn" class="w-3 h-3" />
            <span>Product Coming Soon</span>
          </div>
          <span>COMING SOON</span>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { useStore } from "vuex";
export default {
  name: "Rankings",
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    isLoggedIn: Boolean,
  },
  setup(pros) {
    const store = useStore();
    const slugUrl = (slug) => {
      const base_url = process.env.BASE_URL || "/";
      return base_url + slug;
    };
    function setRank() {
      console.log("SetRank");
      store.dispatch("setIsRank", true);
    }
    const isDateCheck = (a) => (a.filter((r) => r).length ? true : false);

    const checkFirstLanded = (r) => {
      return r.some((e) =>
        e
          ?
          (
            (new Date(e).getFullYear() == new Date().getFullYear()) &&
            (new Date(e).getMonth() == new Date().getMonth()) &&
            (new Date().getDate() - new Date(e).getDate() <= 7)
          )
          : false
      );
    };

    return {
      slugUrl,
      isDateCheck,
      checkFirstLanded,
      setRank,
    };
  },
};
</script>

<style lang="scss" scoped>
.product-analytics {
  @apply flex flex-col divide-y divide-brand-ash divide-opacity-50 rounded-lg shadow-box bg-white;

  .pa-stats {
    @apply relative grid grid-cols-1 md:grid-cols-2 divide-y md:divide-x md:divide-y-0 divide-brand-ash divide-opacity-50 items-stretch bg-white rounded-b-lg overflow-hidden z-20;

    .blurry-banner {
      @apply flex flex-row items-center justify-center px-5;

      p {
        @apply text-md text-theme-body font-light m-0 w-36;
      }

      svg {
        @apply block w-7 h-7 mr-4 flex-shrink-0 text-theme-body leading-6;
      }
    }

    > div:not(.blurry-banner) {
      @apply flex md:flex-col items-center justify-between md:justify-start space-y-2 p-4;

      p {
        @apply font-normal text-brand-secondary text-opacity-90 text-xl;

        strong {
          @apply opacity-40 mr-0.5 font-normal;
        }
      }

      .pa-link {
        @apply block text-sm md:text-xxs uppercase text-brand-body underline text-center;
        text-decoration-style: dashed;
      }
    }
  }

  .pa-banner {
    @apply flex items-center justify-between leading-none px-3 pb-1.5 pt-2.5 -mt-1 rounded-b-lg z-10;
    border-top: 0 !important;
    background: #e7e7e7;
    font-size: 11px;
    color: rgba(123, 123, 123, 0.65);
  }
}
</style>
