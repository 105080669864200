<template>
  <div
    class="w-full grid grid-cols-6 2xl:grid-cols-4 gap-1.5"
    :class="toggleImages ? 'show-all' : 'show-less'"
  >
    <div
      v-for="(img, idx) in images"
      :key="img.id"
      class="image-elem"
      @click="$emit('change', idx)"
    >
      <img
        :src="img.src || img.base_path + '/' + img.file_name"
        class="w-full h-full object-cover object-center"
        :class="{ active: getCurrentIdx === idx }"
      />
    </div>
    <div
      v-if="images.length > 7"
      class="toggler"
      @click="toggleImages = !toggleImages"
    >
      <svg
        width="15"
        height="10"
        viewBox="0 0 15 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.145 0.5L7.5 6.06275L12.855 0.5L14.5 2.21255L7.5 9.5L0.5 2.21255L2.145 0.5Z"
          fill="#ADADAD"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from "vue";
export default {
  name: "ImagesGrid",
  props: {
    images: {
      type: Array,
      default: () => [],
    },
    current: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const toggleImages = ref(false);

    const getCurrentIdx = computed(() => props.current);

    watch(getCurrentIdx, (idx) => {
      if (idx >= 7) {
        toggleImages.value = true;
      }
    });

    return {
      toggleImages,
      getCurrentIdx,
    };
  },
};
</script>

<style lang="scss" scoped>
.show-less {
  .image-elem:nth-child(1n + 8) {
    @apply hidden;
  }
}
.image-elem {
  @apply flex items-center justify-center;
  img {
    @apply rounded border border-gray-200 max-w-full cursor-pointer;

    &:hover,
    &.active {
      border-color: #7ab1e4;
    }
  }
}

.toggler {
  @apply w-full border border-gray-200 rounded text-black text-xs font-medium flex items-center justify-center cursor-pointer;
  background: #f1f1f1;
  aspect-ratio: 1/1;
  svg {
    @apply w-3.5 h-3.5 transition-transform duration-500;
  }

  &:hover {
    border-color: #7ab1e4;
  }
}

.show-all {
  .image-elem:nth-child(1n + 8) {
    animation: scale-display 0.3s;
  }

  .toggler svg {
    @apply transform rotate-180;
  }
}
@keyframes scale-display {
  0% {
    opacity: 0;
    transform: scale(0);
    -webkit-transform: scale(0);
  }

  100% {
    opacity: 1;
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

@keyframes scale-display--reversed {
  0% {
    display: inline-flex;
    opacity: 1;
    transform: scale(1);
    -webkit-transform: scale(1);
  }
  99% {
    display: inline-flex;
    opacity: 0;
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  100% {
    display: none;
    opacity: 0;
    transform: scale(0);
    -webkit-transform: scale(0);
  }
}
</style>
