<template>
  <div class="prod-brand bg-white rounded-lg shadow-box overflow-hidden">
    <div class="flex items-center space-x-4 px-3 py-3">
      <div
        class="brand-img flex justify-center items-center w-16 h-16 p-2 rounded-full border border-theme-border border-opacity-50 flex-shrink-0"
      >
        <img :src="img" class="max-w-full" />
      </div>
      <div class="flex flex-col">
        <router-link :to="url">
          <p class="text-base font-semibold text-theme-secondary">{{ name }}</p>
        </router-link>
      </div>
    </div>
    <router-link :to="url">
      <button
        class="w-full bg-brand-ash bg-opacity-20 text-xs font-normal uppercase leading-none py-3 text-brand-body hover:text-brand-secondary"
      >
        View All
      </button></router-link
    >
  </div>
</template>

<script>
export default {
  name: "BrandCard",
  props: {
    img: String,
    name: String,
    url: String,
  },
};
</script>
